/* global site */

// Import site modules
// import ('./site/cookie-policy.js');
import ('./site/scroll-to.js');
import ('./site/nav.js');

// import a11yChecker from 'a11y-checker';

window.site = (window.site || {});

/**
 * Main application class.
 * @class App
 * @static
 */
window.site.App = (function App() {
  /**
   * Has the class been initialized?
   * @private
   */
  let inited = false;

  /**
   * Application config defaults.
   * @private
   * @param config.env     Current server environment
   * @param config.csrf    Security token to submit with forms
   * @param config.csrfName    Security token to submit with forms
   * @param config.locale  Current locale short code
   * @param config.device  Device detection based on browser signature
   * @param config.preview Page is shown through live preview mode
   * @param config.general Settings from general config
   */
  const config = {
    env: 'production',
    csrf: null,
    csrfName: null,
    locale: 'en',
    device: 'desktop',
    preview: false,
    general: {},
  };

  /**
   * Initializes the class.
   * @public
   */
  const init = function init(options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Store application settings
    options = (options || {});

    if (options.env) { config.env = options.env; }
    if (options.csrf) { config.csrf = options.csrf; }
    if (options.csrfName) { config.csrfName = options.csrfName; }
    if (options.locale) { config.locale = options.locale; }
    if (options.device) { config.device = options.device; }
    if (options.preview) { config.preview = options.preview; }
    if (options.general) { config.general = options.general; }

    // Global
    // Detect css-grid un-supported
    const el = document.createElement("div")
    const supportsGrid = "string" == typeof el.style.grid;

    if(!supportsGrid) {
      const body = document.getElementsByTagName("body")[0].classList.add('is-ie-11');
    } else {
      // IE 11 || Old Browser - Ignore zone
      console.table(options);
      // a11yChecker();

      // Register ServiceWorker
      // if ('serviceWorker' in navigator) {
      //   console.log('Registering ServiceWorker...');
      //   window.addEventListener('load', function() {
      //       navigator.serviceWorker.register('/sw.js', {
      //           scope: "/"
      //       }).then(function(registration) {
      //           // Registration was successful
      //           console.log('ServiceWorker registration successful with scope: ', registration.scope);
      //           // Trim the caches on load
      //           navigator.serviceWorker.controller && navigator.serviceWorker.controller.postMessage({
      //               command: "trimCaches"
      //           });
      //      }).catch(function(err) {
      //           // registration failed :(
      //           console.log('ServiceWorker registration failed: ', err);
      //       });
      //   });
      // }

      // PreRender for fast website
      function prerenderlink(e) {
        console.log(e, e.currentTarget, e.currentTarget.href);
        var head = document.getElementsByTagName("head")[0];
        var refs = head.childNodes;
        var ref = refs[ refs.length - 1];

        var elements = head.getElementsByTagName("link");
        Array.prototype.forEach.call(elements, function(el, i) {
          if (("rel" in el) && (el.rel === "prerender"))
            el.parentNode.removeChild(el);
        });

        var prerenderTag = document.createElement("link");
        prerenderTag.rel = "prerender";
        prerenderTag.href = e.currentTarget.href;
        ref.parentNode.insertBefore(prerenderTag,  ref);
      }

      const $prerenderLinks = document.querySelectorAll('#page-header a');
      [].slice.call($prerenderLinks).forEach(function(elem) {
        elem.addEventListener('mouseenter', prerenderlink);
      });

      const $prerenderLinksContent = document.querySelectorAll('[data-main-content-links] a');
      [].slice.call($prerenderLinksContent).forEach(function(elem) {
        elem.addEventListener('mouseenter', prerenderlink);
      });

      const linkWithSubnav = document.querySelector('.with-subnav a'); 
      const subnav = document.querySelector('.subnav');
      const pageHeader = document.querySelector('.page-header');

      if (linkWithSubnav) {
        linkWithSubnav.addEventListener('click', function(e) {
           e.preventDefault();

          linkWithSubnav.classList.toggle('is-active');

          if (linkWithSubnav.classList.contains('is-active')) {
            subnav.classList.add('is-active');
            pageHeader.classList.add('force-alt');
            // scroll to top
            window.scrollTo(0, 0);
            // Prevent scrolling
            document.body.classList.add('no-scroll');

            // Links tabindex
            var $subnavLinks = [].slice.call(document.querySelectorAll('.subnav a'));
            $subnavLinks.forEach(function($elem) {
              $elem.setAttribute('tabindex', '0');
            } , false);
          } else {
            subnav.classList.remove('is-active');
            pageHeader.classList.remove('force-alt');
            // Prevent scrolling
            document.body.classList.remove('no-scroll');

            // Links tabindex
            var $subnavLinks = [].slice.call(document.querySelectorAll('.subnav a'));
            $subnavLinks.forEach(function($elem) {
              $elem.setAttribute('tabindex', '-1');
            } , false);
          }
        });

        // Close subnav on click outside
        document.addEventListener('click', function(e) {
          if (!e.target.closest('.with-subnav')) {
            
            if (e.target.closest('.subnav')) {
              return;
            }

            linkWithSubnav.classList.remove('is-active');
            subnav.classList.remove('is-active');
            pageHeader.classList.remove('force-alt');
            // Prevent scrolling
            document.body.classList.remove('no-scroll');

            // Links tabindex
            var $subnavLinks = [].slice.call(document.querySelectorAll('.subnav a'));
            $subnavLinks.forEach(function($elem) {
              $elem.setAttribute('tabindex', '-1');
            } , false);
          }
        } , false);
      }

      const mobileLinkSubnav = document.querySelector('.mobile-link-subnav');
      const mobileSubnav = document.querySelector('.link-subnav');

      if (mobileLinkSubnav) {
        mobileLinkSubnav.addEventListener('click', function(e) {
          // e.preventDefault();

          // mobileLinkSubnav.classList.toggle('is-active');
          mobileSubnav.classList.toggle('is-active');
        });
      }


      const arrondissementCta = [].slice.call(document.querySelectorAll('a[href="#arrondissements"]'));
      arrondissementCta.forEach(function(elem) {
        elem.addEventListener('click', function(e) {
          e.preventDefault();

          // Smooth Scroll to top of page
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });

          setTimeout(function() {
            // Open subnav
            document.querySelector('.main-nav .nav-link-item.with-subnav a').click();
          }, 500);
        });
      });

      const topCta = [].slice.call(document.querySelectorAll('a[href="#top"]'));
      topCta.forEach(function(elem) {
        elem.addEventListener('click', function(e) {
          e.preventDefault();

          // Smooth Scroll to top of page
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        });
      });

      const topSiteAlertMessage = document.querySelector('.top-alert-message');

      if (topSiteAlertMessage) {
        document.body.classList.add('has-top-alert-message');
        const topSiteAlertMessageHeight = topSiteAlertMessage.offsetHeight;
        // document.querySelector('#page-container').style.paddingTop = topSiteAlertMessageHeight + 'px';
        document.querySelector('.page-header').style.top = 60 + topSiteAlertMessageHeight + 'px';
        document.querySelector('.subnav').style.top = 148 + topSiteAlertMessageHeight + 'px';

        // Window resize
        window.addEventListener('resize', function(e) {
          const topSiteAlertMessage = document.querySelector('.top-alert-message');

          if (!topSiteAlertMessage) {
            return;
          }
          
          const topSiteAlertMessageHeight = topSiteAlertMessage.offsetHeight;
          // document.querySelector('#page-container').style.paddingTop = topSiteAlertMessageHeight + 'px';
          document.querySelector('.page-header').style.top = 60 + topSiteAlertMessageHeight + 'px';
          document.querySelector('.subnav').style.top = 148 + topSiteAlertMessageHeight + 'px';
        });

        const closeTopSiteAlertMessage = document.querySelector('.top-alert-message .icon-close');

        closeTopSiteAlertMessage.addEventListener('click', function(e) {
          e.preventDefault();
          topSiteAlertMessage.remove();
          document.body.classList.remove('has-top-alert-message');
          document.querySelector('.page-header').style.top = 60 + 'px';
          document.querySelector('.subnav').style.top = 148 + 'px';

          // Local storage
          localStorage.setItem('pmtl-top-alert', topSiteAlertMessage.querySelector('p').innerText);
        });

        console.log(localStorage.getItem('pmtl-top-alert'));
        if (localStorage.getItem('pmtl-top-alert') === topSiteAlertMessage.querySelector('p').innerText) {
          topSiteAlertMessage.remove();
          document.body.classList.remove('has-top-alert-message');
          document.querySelector('.page-header').style.top = 60 + 'px';
          document.querySelector('.subnav').style.top = 148 + 'px';

          // Local storage
          localStorage.setItem('pmtl-top-alert', topSiteAlertMessage.querySelector('p').innerText);
          return;
        }
      }


      if (document.querySelector('[data-open-popup]') && document.querySelector('[data-popup]')) {

        var $openPopup = [].slice.call(document.querySelectorAll('[data-open-popup]'));
        $openPopup.forEach(function($elem) {
          $elem.addEventListener('click', function(e) {
            e.preventDefault();

            var type = $elem.getAttribute('data-open-popup');
            document.querySelector('[data-popup="'+type+'"]').classList.toggle('is-active');
          });
        });
      }

      [].slice.call(document.querySelectorAll('[data-filter]')).forEach(function(elem) {
        elem.addEventListener('change', function(e) {
          e.preventDefault();
          elem.closest('form').submit();
        });
      });
    }

    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (isSafari) {
      document.querySelector('.membres-grid').classList.remove('anim');
    }

    // Return success
    return true;
  };

  /**
   * Getter for application config.
   * @public
   */
  const getConfig = function getConfig(option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init,
    config: getConfig,
  };
}());
